import React, { useState, useEffect, useRef } from 'react';
import '../styles/index.css';
import logo from '../assets/nsbe_logo-removebg-preview.png';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css';

function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', closeMenu);
        return () => {
            document.removeEventListener('click', closeMenu);
        };
    }, []);

    useEffect(() => {
        const listItems = document.querySelectorAll('#navbar-sticky ul li');
        listItems.forEach((item, index) => {
            if (isMenuOpen) {
                item.style.animationDelay = `${index * 0.1}s`;
                if (index % 2 === 0) {
                    item.classList.add('slide-in-left');
                } else {
                    item.classList.add('slide-in-right');
                }
            } else {
                item.classList.remove('slide-in-left', 'slide-in-right');
            }
        });
    }, [isMenuOpen]);

    return (
        <nav className="sticky w-full z-20 top-0 start-0 bg-theme-color">
            <div className=" flex flex-wrap items-center justify-evenly mx-auto p-4">
                <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse place-items-start">
                    <img src={logo} className="h-8" alt="NSBEUofC Logo" />
                    <span className="self-center text-xl text-white text-shadow-black font-semibold whitespace-nowrap">NSBEUCALGARY</span>
                </a>
                <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse" ref={menuRef}>
                    <button onClick={toggleMenu} type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-white rounded-lg md:hidden hover:bg-neon-color focus:outline-none focus:ring-2 focus-ring-neon-color" aria-controls="navbar-sticky" aria-expanded={isMenuOpen ? "true" : "false"}>
                        <span className="sr-only">Open main menu</span>
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                        </svg>
                    </button>
                </div>
                <div className={`flex justify-end w-full text-white md:flex md:w-auto md:order-1 ${isMenuOpen ? 'block' : 'hidden'}`} id="navbar-sticky">
                    <ul className="flex flex-col p-4 text-right md:p-0 mt-4 font-medium text-2xl md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 bg-transparent rounded-lg md:bg-transparent md:text-xl">
                        <li className='nav-button'>
                            <Link to="/">
                                Home
                            </Link>
                        </li>
                        <li className='nav-button'>
                            <Link to="/AboutUs">
                                About Us
                            </Link>
                        </li>
                        <li className='nav-button'>
                            <Link to="/Event" >
                                Events
                            </Link>
                        </li>
                        <li className='nav-button'>
                            <Link to="/Member" >
                                Our Team
                            </Link>
                        </li>
                        <li className='nav-button'>
                            <Link to="/GetInvolved" >
                                Get Involved
                            </Link>
                        </li>
                        <li className='nav-button'>
                            <Link to="/Contact" >
                                Contact
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;

