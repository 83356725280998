import React, { useState, useEffect } from 'react';
import '../styles/Member.css';
import Tumi from '../assets/photos/members/Tumi.png';
import Eshi from '../assets/photos/members/Eshilama Akalumhe.jpg';
import Amanda from '../assets/photos/members/Amanda Tamakloe.jpg';
import Fizzy from '../assets/photos/members/Oluwafiasyo Ababs.jpg';
// import holder from '../assets/photos/members/blackBoy.png';
import Shine from '../assets/photos/members/shine David  Daniel.png';
import Emiko from '../assets/photos/members/Emiko.png';
import Dyenaan from '../assets/photos/members/Dyenaan Dapoet.png';
import Ire from '../assets/photos/members/Ireoluwa Fagbuyi.jpg';
import Tolu from '../assets/photos/members/Toluwalase.png'
import Mufaro from '../assets/photos/members/Mufaro.png'
import Seyi from '../assets/photos/members/Seyi.jpg'
import Dami from '../assets/photos/members/Damilola.jpg'

import { FaLinkedin } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

import { IoLogoLinkedin } from "react-icons/io5";
import { RiDiscordLine } from "react-icons/ri";
import { IoLogoInstagram } from "react-icons/io5";
import { SiFacebook } from "react-icons/si";
import { FaXTwitter } from "react-icons/fa6";

function Member() {
    const activeMemberId = useState(null);
    const [activeMember, setActiveMember] = useState(null);
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 768);
    const [activeOn, setActiveOn] = useState(false);
    // console.log("Large mode: ", isLargeScreen);
    // console.log("Active mode: ", activeOn);
    // console.log("Active member: ", activeMember);

    const teamMembers = [
        { id: 1, photo: Tumi, name: 'Tumi Akalumhe', position: 'President', description: 'My name is Akalumhe Tumi and I am a 4th year electrical engineering student with a digital engineering minor at the University Of Calgary. I am the Founder/Co-President of the National Society of Black Engineers (NSBE) at the University of Calgary. I am an international student from Nigeria who is ongoing a 16-month term as an Automation and Advanced Process Control (APC) Intern, I am very involved in robotics, software development and the use of green energy in advancing technologies. I am also very passionate about advocacy for underrepresented groups, having volunteered for numerous groups including Engineers without Borders (EWB) and Robogals UCalgary.', email: "mailto:tumi.akalumhe@ucalgary.ca", linkedin: 'https://www.linkedin.com/in/tumi-akalumhe-0b9352209/' },
        { id: 2, photo: Eshi, name: 'Eshilama Akalumhe', position: 'Vice President', description: 'My name is Eshilama Akalumhe and I’m a third-year Software Engineering with a minor in Mechatronics Engineering at the University of Calgary. I am the Founder/Co-President of the National Society of Black Engineers (NSBE) at the University of Calgary. My journey is fuelled by a deep-seated passion for technology and innovation, accentuated by a strong commitment to fostering diversity in STEM fields, particularly in empowering women of color. I aim to leverage my technical expertise in software development and robotics to spearhead groundbreaking solutions.', email: "mailto:eshilama.akalumhe@ucalgary.ca", linkedin: 'https://www.linkedin.com/in/eshilama-akalumhe-855651214/' },
        { id: 3, photo: Seyi, name: 'Seyi Akinro', position: 'Senator', description: 'My name is Seyi Akinro and I am a second year Mechanical Engineering student at uofc. Currently a member, volunteer, representative and Senator at NSBE UCalgary. I recently volunteered at the Melanin Minds Summer camp. Apart from volunteering, I really enjoy baking.', email: "mailto:seyi.akinro@ucalgary.ca", linkedin: 'https://www.linkedin.com/in/seyi-akinro-7232b4294/' },
        { id: 4, photo: Dami, name: 'Damilola Anifowose', position: 'Senator', description: 'My name is Damilola Anifowose and I am a second year mechanical engineering student at UofC. Currently one of the senator’s and the Outreach coordinator at the National Society of Black Engineers(NSBE) UCalgary. I’m Passionate about taking on community initiatives that promote cultural diversity and empowerment of younger students. Outside of academics and extracurricular, I enjoy reading mystery novels and baking.', email: "mailto:damilola.anifowose@ucalgary.ca", linkedin: 'https://www.linkedin.com/in/dami-anifowose-0a0973282?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app' },
        { id: 5, photo: Shine, name: 'Shine David-Daniel', position: 'Programs Chair', description: "My name is Shine David Daniel and I am a Biomedical Engineering student with a minor in Digital Engineering at the University of Calgary. I currently hold the position of Vice President – Events at the National Society of Black Engineers (NSBE) UCalgary. I'm passionate about health- related research, having previously worked on biomaterials and currently focusing on microwave imaging for breast cancer this summer.As an international student from Nigeria, I deeply care about children's health and strive to make a difference through my studies and research.", email: "mailto:shine.daviddaniel@ucalgary.ca", linkedin: 'https://www.linkedin.com/in/shine-david-daniel' },
        { id: 6, photo: Ire, name: 'Ireoluwa Fagbuyi', position: 'Membership Chair', description: 'My name is Ireoluwa Fagbuyi and I am a 3rd year Mechanical Engineering student at the University of Calgary. I currently hold the position of Vice President – Memberships at the National Society of Black Engineers (NSBE) UCalgary. I am an international student who enjoys playing Sudoku and watching reality shows in my free time. I am very much looking forward to expanding the club and welcoming more members during her tenure.', email: "", linkedin: '' },
        { id: 7, photo: Tolu, name: 'Toluwalase Oseni', position: 'Publication Chair', description: "Toluwalase Oseni, an electrical engineering student with a growing interest in the energy industry, aspires to revolutionize the energy sector across Africa and worldwide. He is a creative thinker, a problem solver, and an aspiring global entrepreneur. Toluwalase enjoys engaging in conversations, networking, and connecting with remarkable individuals, like yourself. He harbors ambitious dreams and remains an optimist, understanding that life has its challenges, but he's determined to overcome them.Toluwalase finds inspiration in his faith and believes that God plays a pivotal role in making dreams come true.", email: "mailto:toluwalase.oseni@ucalgary.ca", linkedin: 'https://www.linkedin.com/in/toluwalase-oseni-b5633a1b2/' },
        { id: 8, photo: Mufaro, name: 'Mufaro Mungoni', position: 'Professional Development Chair', description: "My name is Mufaro Mungoni  and I am a fifth year software Engineering student at uofc. Currently the outreach and professional development chair at NSBE UCalgary. I have volunteered with numerous clubs at university of calgary, serving as the vp academic for the ESS among many others. I am passionate about bridging the gap for underrepresented groups and love being a part of NSBE!", email: "mailto:mufaro.mungoni@ucalgary.ca", linkedin: 'https://www.linkedin.com/in/mufaro-mungoni-96982916a/' },
        { id: 9, photo: Fizzy, name: 'Oluwafisayo Adabs', position: 'Technology Chair', description: "Hey everyone as it says my name is Oluwafisayo Adabs but you can also call me Fizzy. I am a 3rd year Software Engineering student with a minor in Mechatronics Engineering. I'm really passionate about software and robotics, and I'm excited to support NSBE UCalgary by bringing the best tech and software to our events and entertainment. In my free time, I love playing sports, reading books, and I'm also trying to get into gaming(I'm not that great yet) so I can learn how to code games.", email: "mailto:oluwafisayo.adabs@ucalgary.ca", linkedin: 'https://www.linkedin.com/in/oluwafisayo-adabs-/' },
        {
            id: 10, photo: Emiko, name: 'Emiko Emiko', position: 'Pre-college initiative Chair (PCI)', description: 'My name is Emiko Emiko and I am a third-year Software Engineering student at the University of Calgary. I currently hold the position of the Vice President at the National Society of Black Engineers (NSBE) UCalgary. I’m an international student from Nigeria and am very passionate about problem solving using programming technologies, as well as fashion design. In my free time, I enjoy listening to music and thrifting.', email: "mailto:emiko.emiko@ucalgary.ca", linkedin: 'https://www.linkedin.com/in/emiko-emiko/'
        },
        { id: 11, photo: Dyenaan, name: 'Dyenaan Dapoet', position: 'T.O.R.C.H', description: 'I’m a 4th-year Software engineering student at the University of Calgary. Currently, I hold the position of the Vice President Academics at the National Society of Black Engineers (NSBE) UCalgary, and I am looking forward to an exciting year at the club.  In my free time, I enjoy learning African history and playing sports.', email: "mailto:dyenaan.dapoet@ucalgary.ca", linkedin: 'https://www.linkedin.com/in/dyenaan-dapoet-053416205/' },
        { id: 12, photo: Amanda, name: 'Amanda Tamakloe', position: 'Secretary', description: "Hi everyone! I am Amanda Tamakloe, and I am going into my final year of Chemical Engineering, minoring in Digital Engineering. I am honored to hold the position of the Secretary at National Society of Black Engineers (NSBE) at the University of Calgary this year and super excited to be a part of a group of young people passionate about making a difference and creating safe spaces within the broader STEM discipline. As we fully delve into NSBE’s first year at the University of Calgary, I look forward to all the exciting events and opportunities NSBE will bring to you! If you have any questions about getting involved with us or would like to collaborate with us, don't hesitate to get in touch with me at amanda.tamakloe@ucalgary.ca.I look forward to hearing from you!", email: 'mailto:amanda.tamakloe@ucalgary.ca', linkedin: 'https://www.linkedin.com/in/amanda-tamakloe/' },
    ];

    const handleMemberClick = (member) => {
        setActiveMember(member);
        setActiveOn(true);
    };

    const closeModal = () => {
        setActiveMember(null);
        setActiveOn(false);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth >= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <section className="section3" id="our-team">
                <h2 className="page-header">OUR TEAM</h2>
                <div className="max-w-3xl mx-auto bg-white my-6 font-[sans-serif]">
                    <div className="mx-auto w-full">
                        <h2 className="text-gray-800 text-2xl sm:text-3xl md:text-4xl font-extrabold text-center mb-4">2023 - 2024</h2>

                        {/* First Row (2 members) */}
                        <div className="flex flex-col items-center gap-4 justify-center mx-auto md:flex-row">
                            {teamMembers.slice(0, 2).map((member) => (
                                <div
                                    key={member.id}
                                    className={`team-block ${activeMemberId === member.id ? 'active' : ''}`}
                                    onClick={() => handleMemberClick(member)}
                                >
                                    <img src={member.photo} alt={member.name} className="w-full h-auto" />
                                    <div className="text-container bg-theme-color text-white flex flex-col items-center justify-center mx-auto pt-4 font-semibold w-full">
                                        <h3 className="text-lg sm:text-xl md:text-2xl">{member.name}</h3>
                                        <h4 className="text-md sm:text-lg">{member.position}</h4>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* Second Row (3 members) */}
                        <div className="flex flex-col items-center gap-4 justify-center mx-auto md:flex-row">
                            {teamMembers.slice(2, 5).map((member) => (
                                <div
                                    key={member.id}
                                    className={`team-block ${activeMemberId === member.id ? 'active' : ''}`}
                                    onClick={() => handleMemberClick(member)}
                                >
                                    <img src={member.photo} alt={member.name} className="w-full h-auto" />
                                    <div className="text-container bg-theme-color text-white flex flex-col items-center justify-center mx-auto pt-4 font-semibold w-full">
                                        <h3 className="text-lg sm:text-xl md:text-2xl">{member.name}</h3>
                                        <h4 className="text-md sm:text-lg">{member.position}</h4>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* Third Row (3 members) */}
                        <div className="flex flex-col items-center gap-4 justify-center mx-auto md:flex-row">
                            {teamMembers.slice(5, 8).map((member) => (
                                <div
                                    key={member.id}
                                    className={`team-block ${activeMemberId === member.id ? 'active' : ''}`}
                                    onClick={() => handleMemberClick(member)}
                                >
                                    <img src={member.photo} alt={member.name} className="w-full h-auto" />
                                    <div className="text-container bg-theme-color text-white flex flex-col items-center justify-center mx-auto pt-4 font-semibold w-full">
                                        <h3 className="text-lg sm:text-xl md:text-2xl">{member.name}</h3>
                                        <h4 className="text-md sm:text-lg">{member.position}</h4>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* Fourth Row (3 members) */}
                        <div className="flex flex-col items-center gap-4 md:flex-row md:items-center md:justify-center">
                            {teamMembers.slice(8, 11).map((member) => (
                                <div
                                    key={member.id}
                                    className={`team-block ${activeMemberId === member.id ? 'active' : ''}`}
                                    onClick={() => handleMemberClick(member)}
                                >
                                    <img src={member.photo} alt={member.name} className="w-full h-auto" />
                                    <div className="text-container bg-theme-color text-white flex flex-col items-center justify-center mx-auto pt-4 font-semibold w-full">
                                        <h3 className="text-lg sm:text-xl md:text-2xl">{member.name}</h3>
                                        <h4 className="text-md sm:text-lg">{member.position}</h4>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* Fifth Row (2 members) */}
                        <div className="flex flex-col items-center gap-4 justify-center mx-auto md:flex-row">
                            {teamMembers.slice(11, 13).map((member) => (
                                <div
                                    key={member.id}
                                    className={`team-block ${activeMemberId === member.id ? 'active' : ''}`}
                                    onClick={() => handleMemberClick(member)}
                                >
                                    <img src={member.photo} alt={member.name} className="w-full h-auto" />
                                    <div className="text-container bg-theme-color text-white flex flex-col items-center justify-center mx-auto pt-4 font-semibold w-full">
                                        <h3 className="text-lg sm:text-xl md:text-2xl">{member.name}</h3>
                                        <h4 className="text-md sm:text-lg">{member.position}</h4>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {activeOn && activeMember && (
                        <div className="modal fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" onClick={closeModal}>
                            <div className="modal-content bg-white p-6 rounded-lg" onClick={(e) => e.stopPropagation()}>
                                <span className="close text-2xl cursor-pointer absolute top-2 right-4" onClick={closeModal}>&times;</span>
                                <img src={activeMember.photo} alt={activeMember.name} className='mx-auto' />
                                <p className="mt-4 text-gray-700">{activeMember.description}</p>
                                <div className="text-white flex flex-row justify-end space-x-4 text-2xl mt-4">
                                    <a href={activeMember.email} target="_blank" rel="noopener noreferrer"><MdEmail className="icon" /></a>
                                    <a href={activeMember.linkedin} target="_blank" rel="noopener noreferrer"><FaLinkedin className="icon" /></a>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </section>
            <footer className="bg-theme-color">
                <div className="mx-auto w-11/12 lg:w-3/5 max-w-screen-xl p-4 py-6 lg:py-8">
                    <div className="flex flex-col md:flex-row justify-between text-white font-medium text-lg lg:text-xl">
                        <div className="flex flex-col items-center md:items-start">
                            <div className="pb-4 flex items-center space-x-2 hover:underline hover:cursor-pointer" onClick={() => window.location.href = 'mailto:nsbeuofc@gmail.com'}>
                                <MdEmail />
                                <span>nsbeuofc@gmail.com</span>
                            </div>
                            <div className="flex items-center space-x-2 hover:underline hover:cursor-pointer" onClick={() => window.open('https://www.google.com/maps/dir/?api=1&destination=2500+University+Dr+NW,+Calgary,+AB', '_blank')}
                            >
                                <FaMapMarkerAlt />
                                <div>2500 University Dr NW</div>
                            </div>
                        </div>
                        <div className="flex flex-col items-center pt-4 md:pt-0">
                            <div className="mb-4 px-6 py-2 border bg-white text-black">FOLLOW US</div>
                            <div className="flex justify-between space-x-4">
                                <a href="https://www.linkedin.com/company/nsbe-university-of-calgary/" target="_blank" rel="noopener noreferrer">
                                    <IoLogoLinkedin className="member-icon-button" />
                                </a>
                                <a href="https://discord.gg/7hJnzrur" target="_blank" rel="noopener noreferrer">
                                    <RiDiscordLine className="member-icon-button" />
                                </a>
                                <a href="https://www.instagram.com/nsbeucalgary?igsh=dTc5NG4wZDc1NmF4" target="_blank" rel="noopener noreferrer">
                                    <IoLogoInstagram className="member-icon-button" />
                                </a>
                                <a href="https://www.facebook.com/profile.php?id=61554684322155&mibextid=PtKPJ9" target="_blank" rel="noopener noreferrer">
                                    <SiFacebook className="member-icon-button" />
                                </a>
                                <a href="https://x.com/nsbeuofc" target="_blank" rel="noopener noreferrer">
                                    <FaXTwitter className="member-icon-button" />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="mt-6 flex justify-center">
                        <span className="text-sm text-black font-semibold md:text-lg">© 2023 <a href="https://nsbeucalgary.ca/" className="hover:underline">NSBEUCALGARY</a>. All Rights Reserved.
                        </span>
                    </div>
                </div>
            </footer>
        </>
    );


}

export default Member;