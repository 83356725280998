import { useSpring, animated } from "react-spring";
import { useState } from "react";
// import InfoCard from "../components/GetInvolved/InfoCard";
// import SponsorCard from "../components/GetInvolved/SponsorCard";
import '../styles/index.css';
import Footer from './Footer';

function Volunteer() {

    const springs = useSpring({
        from: { x: -500 },
        to: { x: 0 },
    });
    const [open, setOpen] = useState(false);

    const toggleFact = () => {
        setOpen(open ? false : true);
    }

    const infoData = [
        {
            header: "ACADEMIC EXCELLENCE PROGRAMS",
            info: ["Mentorship Program", "Study Night and Workshops", "Fall Regional Conference", "Annual NSBE Convention"]
        },
        {
            header: "HIGH SCHOOL OUTREACH PROGRAMS ",
            info: ["Brain Stem Alliance", "While She is True"]
        },
        {
            header: "PROFESSIONAL DEVELOPMENT PROGRAMS",
            info: ["Career Fair", "Professional Development Workshops"]
        },
        {
            header: "TECHNICAL DEVELOPMENT PROGRAMS",
            info: ["Engineering Case Competitions", "Annual Hackathon"]
        }
    ]
    const columnTwoData = [
        {
            header: "Champion Diversity and Inclusion",
            description: "By sponsoring our chapter, you demonstrate your commitment to diversity and inclusion in STEM fields. This not only supports a more equitable workforce but also drives innovation and creativity within your organization",
        },
        {
            header: "Access to Top Talent",
            description: "As a sponsor, you gain direct access to a pool of over a 100+ of some of the brightest and most ambitious graduate and undergraduate students in the engineering, technology, science and math fields.",
        },
        {
            header: "Support a prestigious organization",
            description: "Partnering with NSBE, a well-respected and established organization, and support innovative programs and initiatives that aligns your brand with excellence in engineering and technology.",
        }
    ]
    const sponsorLevels = [
        {
            heading: "PLATINUM",
            color: "#E5E4E2",
            description: ["Logo placement on our official website", "Company logo on banners and merchandise", "Unlimited promotional efforts on social media, newsletter and our official website", "4 Representatives invited to prestigious Black Legacy Dinner"],
            price: "$5000",
        },
        {
            heading: "GOLD",
            color: "slate-500",
            description: ["Logo placement on the official website", "Company logo on banners and merchandise", "3 promotional efforts on social media, newsletter and our official website", "3 Representatives invited to prestigious Black Legacy Dinner"],
            price: "$2500",
        },
        {
            heading: "SILVER",
            color: "gray-600",
            description: ["Logo placement on our official website", "2 promotional efforts on social media, newsletter and our official website", "2 Representatives invited to prestigious Black Legacy Dinner"],
            price: "$1000",
        },
        {
            heading: "BRONZE",
            color: "blue-900",
            description: ["Logo placement on our official website", "Promotional effort on social media, newsletter and our official website", "1 representative invited to Black Legacy Dinner"],
            price: "$750",
        },
    ]
    /*page-header: potential replacement at line 40*/
    return (
        <div className="getInvolvedContainer h-full content-center bg-white">
            <h2 className="page-intro w-11/12 sm:w-1/2 pb-8 text-center text-4xl leading-9 font-extrabold text-black mx-auto slide-in-right mt-16">Get Involved</h2>

            <div className="w-11/12 sm:w-8/12 mx-auto flex flex-col sm:flex-row justify-between items-center">
                <button className="bg-theme-color text-white font-semibold hover:bg-teal-300 hover:text-black transition hover:-translate-y-1 hover:scale-80 ease-in-out delay-100 p-4 rounded-full mb-4 sm:mb-0" onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSfplx30HQHxu1OfNIjlSgTWFdMEh1KOWg6SuE553nPf1osPXA/viewform', '_blank')}
                >
                    Become a Member
                </button>
                <button className="bg-theme-color text-white font-semibold hover:bg-teal-300 hover:text-black transition hover:-translate-y-1 hover:scale-80 ease-in-out delay-100 p-4 rounded-full" onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSdzXVDymXD1padvy13AceWAT4f6EaNdWLxt9Vq_PB8VGJyFjA/viewform', '_blank')}
                >
                    Join Our Mentorship Program
                </button>
            </div>
            <div className="flex justify-center items-center">
                <div className="w-11/12 p-4 flex flex-col sm:flex-row justify-between">
                    <button className="bg-theme-color text-white font-semibold hover:bg-teal-300 hover:text-black transition hover:-translate-y-1 hover:scale-80 ease-in-out delay-100 p-4 rounded-full mb-4 sm:mb-0" onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSfplx30HQHxu1OfNIjlSgTWFdMEh1KOWg6SuE553nPf1osPXA/viewform', '_blank')}
                    >
                        Volunteer With Us
                    </button>
                    <button className="bg-theme-color text-white font-semibold hover:bg-teal-300 hover:text-black transition hover:-translate-y-1 hover:scale-80 ease-in-out delay-100 p-4 rounded-full" onClick={() => window.open('https://mailchi.mp/ffc3b0345e78/nsbe-ucalgary-newsletter', '_blank')}
                    >
                        Join Mailing List
                    </button>
                </div>
            </div>

            <section className="bg-theme-color p-8 sm:p-16">
                <div className="join-us text-center bg-white items-center justify-center grid grid-cols-1 sm:grid-cols-1 rounded-lg shadow-neon-color shadow-md md:grid-cols-1 lg:grid-cols-2 gap-8 transition hover:-translate-y-1 p-8 sm:p-16 hover:scale-80 ease-in-out delay-100 w-11/12 sm:w-3/4 mx-auto">
                    {infoData.map((dataItem) => (
                        <div className="bg-theme-color w-full sm:w-80 h-auto sm:h-80 mx-auto p-4 sm:p-0">
                            <h1 className="inv-header bg-neon-color text-xl pt-8 font-extrabold text-black">{dataItem.header}</h1>
                            <ul className="inv-info tracking-tight pt-10 font-mono font-semibold text-lg text-center text-white my-4 px-4 dark:text-white">
                                {dataItem.info.map((item) => (
                                    <li>{item}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </section>

            <section>
                <h1 className="sponsor-section-header w-full text-center text-3xl leading-9 font-extrabold text-theme-color mx-auto pt-12 sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">SPONSORSHIP</h1>
                <div className="sponsorship first bg-theme-color rounded-lg shadow-teal-800 w-11/12 sm:w-3/4 mx-auto pb-16">
                    <div className="sponsorship-main text-center items-center justify-center grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-8 transition hover:-translate-y-1 p-8 sm:p-16 mt-8 sm:mt-16 hover:scale-80 ease-in-out delay-100 mx-auto">
                        <div className="bg-white p-8 w-full  w-3/4 md:w-5/6 mx-auto">
                            <p className="text-sm sm:text-base md:text-lg">
                                In Canada, black people make up 4.3% of the total population with a higher rate of unemployment and a lower median income. This gap is even more pronounced in higher education, where only 2% of engineering and technology graduates are Black (Engineers Canada, 2022).
                                At NSBE UCalgary, we are committed to fostering a supportive
                                and inclusive environment for our members. We provide a range
                                of academic and professional programs and activities aimed at
                                promoting academic excellence, professional development, and
                                community engagement. From mentorship programs and
                                networking events to career fairs and high school outreach
                                programs, we strive to empower our members to achieve their full
                                potential and become leaders in their fields.
                                By supporting our chapter, you can help address these disparities and create more opportunities for Black students and professionals in STEM.
                            </p>
                        </div>

                        <div className="bg-white p-8">
                            <h2 className="text-center text-2xl leading-9 font-bold text-teal-900 mx-auto sm:text-3xl sm:leading-10 lg:text-5xl lg:leading-none">WHY BECOME A SPONSOR?</h2>
                            {columnTwoData.map((item) => (
                                <div className="flex flex-col md:flex-col sm:flex-col justify-between mb-4 p-2">
                                    <span className="font-bold text-left sm:text-right md:text-left text-lg">{item.header}</span>
                                    <span className="text-right sm:text-left md:text-right text-lg">{item.description}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <h1 className="sponsor-tier-header text-center text-2xl leading-9 font-extrabold text-white mx-auto slide-in-right">SPONSORSHIP TIERS</h1>
                    <div className="sponsorship-tiers w-11/12 sm:w-3/4  mx-auto bg-slate-100 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-1">
                        {sponsorLevels.map((sponsorItem) => (
                            <div className="sponsorship-tier">
                                <div className={`flip-card-front bg-${sponsorItem.color} p-8 h-full rounded-lg shadow-lg shadow-${sponsorItem.color}`}>
                                    <h2 className="package-head text-center font-extrabold text-black text-xl">{sponsorItem.heading}</h2>
                                    <h1 className="package-price text-center text-xl">{sponsorItem.price}</h1>
                                    <ul className="mt-4 text-left text-md p-2 text-gray-800 text-lg list-disc font-medium">
                                        {sponsorItem.description.map((item) => (
                                            <li>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Volunteer;
