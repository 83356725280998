import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <>
            <div className="get-involved-container mx-auto bg-[#303C55]" id="get-involved">
                <div className="contact-head flex flex-col text-center justify-center mx-auto my-auto">
                    {/* <h1 style={{fontSize: '2.5rem',
                                    fontFamily: 'impact',
                                    color: '#fff',
                                    textShadow: '2px 2px 9px rgba(0, 0, 0, 0.5)'}}>
                        CONTACT US
                        </h1> */}
                    {/* <div className="w-1/5 h-1 bg-[#303C55] mx-auto mt-4"></div> Line after heading */}
                </div>
                {/* <p className="get-involved mt-4   text-center dark:text-white text-lg text-white tracking-tight"
            style={{fontSize: '1.5rem', lineHeight: '1.5', fontFamily: 'nunito'}}>
            Join us as we strive for excellence, innovation, and impact in STEM! Connect with us today to learn more about how you can get involved and make a difference.
            </p> */}
                <div className="social-media-icons flex justify-between mt-4 w-3/4 text-white mx-auto">
                    <a href="https://www.facebook.com/profile.php?id=61554684322155&mibextid=PtKPJ9" target="_blank" rel="noopener noreferrer" className="mx-2 text-md md:text-5xl hover:text-[#3b5998]">
                        <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="https://x.com/nsbeuofc" target="_blank" rel="noopener noreferrer" className="mx-2 text-md md:text-5xl hover:text-[#00acee]">
                        <i className="fab fa-twitter"></i>
                    </a>
                    <a href="https://www.linkedin.com/company/nsbe-university-of-calgary/" target="_blank" rel="noopener noreferrer" className="mx-2 text-md md:text-5xl hover:text-[#0e76a8]">
                        <i className="fab fa-linkedin-in"></i>
                    </a>
                    <a href="https://www.instagram.com/nsbeucalgary?igsh=dTc5NG4wZDc1NmF4" target="_blank" rel="noopener noreferrer" className="mx-2 text-md md:text-5xl hover:text-[#8a3ab9]">
                        <i className="fab fa-instagram"></i>
                    </a>
                    <a href="mailto:nsbeuofc@gmail.com" className="mx-2 text-md md:text-5xl hover:text-[#dd4b39]">
                        <i className="fas fa-envelope"></i>
                    </a>
                    <a href="https://discord.gg/7hJnzrur" target="_blank" rel="noopener noreferrer" className="mx-2 text-md md:text-5xl hover:text-[#7289da]">
                        <i className="fab fa-discord"></i>
                    </a>

                </div>
                {/* <div className="mx-auto pb-12 text-center mt-4">
                <button type="button" className=" transition hover:-translate-y-1 hover:scale-80 ease-in-out delay-100 text-white bg-[#303C55] font-extrabold text-xl tracking-wider hover:shadow-xl hover:bg-[#fff] hover:text-black font-medium px-6 py-4 text-center dark:bg-teal-700 dark:hover:bg-teal-900" 
                style={{borderRadius:"30px"}}>
                     <Link to="/Contact"><i className="fas fa-handshake mx-2"></i> Get Involved</Link>
                    </button>
            
            </div> */}
                <div className="footer bg-[#303C55] text-center text-white dark:text-black py-4 text-sm tracking-tight mx-auto">
                    © 2024 <a href="https://nsbeucalgary.ca/" className="hover:underline">NSBEUCALGARY</a>. All Rights Reserved.
                </div>
            </div>

        </>

    );
}