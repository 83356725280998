import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import '../styles/Contact.css';

import { IoLogoLinkedin } from "react-icons/io5";
import { RiDiscordLine } from "react-icons/ri";
import { IoLogoInstagram } from "react-icons/io5";
import { SiFacebook } from "react-icons/si";
import { FaXTwitter } from "react-icons/fa6";

function Contact() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_t4449fb', 'template_sovxft5', form.current, {
                publicKey: '6KblDM4q7bw82GbNR',
            })
            .then(
                () => {
                    console.log('SUCCESS! Message Sent!');
                    form.current.reset();
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };

    return (
        <>
            <section className="section6" id='contact'>
                <div>
                    <h2 className="page-header">CONTACT US</h2>
                    {/* <div className="flex justify-center items-center space-x-4 sm:space-x-8 text-emerald-900 mb-4 lg:mb-8">
                        <a href='https://www.instagram.com/nsbeucalgary?igsh=dTc5NG4wZDc1NmF4' target='_blank' rel='noopener noreferrer' >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-8 w-8"
                                fill="currentColor"
                                viewBox="0 0 24 24">
                                <path
                                    d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                            </svg>
                        </a>
                        <a href='https://www.linkedin.com/company/nsbe-university-of-calgary/' target='_blank' rel='noopener noreferrer' >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-8 w-8"
                                fill="currentColor"
                                viewBox="0 0 24 24">
                                <path
                                    d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                            </svg>
                        </a>
                        <a href="https://discord.gg/7hJnzrur" target="_blank" rel="noopener noreferrer" >
                            <svg
                                className="h-8 w-8"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                fillRule="evenodd"
                                clipRule="evenodd">
                                <path
                                    d="M19.54 0c1.356 0 2.46 1.104 2.46 2.472v21.528l-2.58-2.28-1.452-1.344-1.536-1.428.636 2.22h-13.608c-1.356 0-2.46-1.104-2.46-2.472v-16.224c0-1.368 1.104-2.472 2.46-2.472h16.08zm-4.632 15.672c2.652-.084 3.672-1.824 3.672-1.824 0-3.864-1.728-6.996-1.728-6.996-1.728-1.296-3.372-1.26-3.372-1.26l-.168.192c2.04.624 2.988 1.524 2.988 1.524-1.248-.684-2.472-1.02-3.612-1.152-.864-.096-1.692-.072-2.424.024l-.204.024c-.42.036-1.44.192-2.724.756-.444.204-.708.348-.708.348s.996-.948 3.156-1.572l-.12-.144s-1.644-.036-3.372 1.26c0 0-1.728 3.132-1.728 6.996 0 0 1.008 1.74 3.66 1.824 0 0 .444-.54.804-.996-1.524-.456-2.1-1.416-2.1-1.416l.336.204.048.036.047.027.014.006.047.027c.3.168.6.3.876.408.492.192 1.08.384 1.764.516.9.168 1.956.228 3.108.012.564-.096 1.14-.264 1.74-.516.42-.156.888-.384 1.38-.708 0 0-.6.984-2.172 1.428.36.456.792.972.792.972zm-5.58-5.604c-.684 0-1.224.6-1.224 1.332 0 .732.552 1.332 1.224 1.332.684 0 1.224-.6 1.224-1.332.012-.732-.54-1.332-1.224-1.332zm4.38 0c-.684 0-1.224.6-1.224 1.332 0 .732.552 1.332 1.224 1.332.684 0 1.224-.6 1.224-1.332 0-.732-.54-1.332-1.224-1.332z" />
                            </svg>
                        </a>
                        <a href='https://www.facebook.com/profile.php?id=61554684322155&mibextid=PtKPJ9' target="_blank" rel='noopener noreferrer' >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-8 w-8"
                                fill="currentColor"
                                viewBox="0 0 24 24">
                                <path
                                    d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                            </svg>
                        </a>
                    </div> */}
                    <div className="max-w-3xl max-lg:max-w-3xl mx-auto bg-white my-6 font-[sans-serif]">
                        <div className="text-center px-6">
                            <h2 className="text-gray-800 text-3xl font-extrabold">Get In Touch</h2>
                            <p className="text-sm text-black mx-auto max-w-md">
                                Have some questions, suggestions or feedback, feel free to reach out using the contact form or information below.
                            </p>
                        </div>

                        <div className="grid lg:grid-cols-3 items-start p-4 shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] bg-contact-color rounded-lg mt-12">

                            <div className="bg-theme-color rounded-lg p-6 h-full max-lg:order-1">
                                <h2 className="text-xl text-white">Contact Information</h2>
                                <p className="text-sm text-gray-300 mt-4 mx-auto max-w-xs text-center">Connect with us</p>

                                <ul className="mt-16 space-y-8">
                                    <li className="flex items-start justify-start">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill='#fff' viewBox="0 0 368.16 368.16" className="mt-1">
                                            <path d="M184.08 0c-74.992 0-136 61.008-136 136 0 24.688 11.072 51.24 11.536 52.36 3.576 8.488 10.632 21.672 15.72 29.4l93.248 141.288c3.816 5.792 9.464 9.112 15.496 9.112s11.68-3.32 15.496-9.104l93.256-141.296c5.096-7.728 12.144-20.912 15.72-29.4.464-1.112 11.528-27.664 11.528-52.36 0-74.992-61.008-136-136-136zM293.8 182.152c-3.192 7.608-9.76 19.872-14.328 26.8l-93.256 141.296c-1.84 2.792-2.424 2.792-4.264 0L88.696 208.952c-4.568-6.928-11.136-19.2-14.328-26.808-.136-.328-10.288-24.768-10.288-46.144 0-66.168 53.832-120 120-120s120 53.832 120 120c0 21.408-10.176 45.912-10.28 46.152z" data-original="#000000"></path>
                                            <path d="M184.08 64.008c-39.704 0-72 32.304-72 72s32.296 72 72 72 72-32.304 72-72-32.296-72-72-72zm0 128c-30.872 0-56-25.12-56-56s25.128-56 56-56 56 25.12 56 56-25.128 56-56 56z" data-original="#000000"></path>
                                        </svg>
                                        <div className="ml-4 text-sm text-gray-500">
                                            <p className="text-white">Address</p>
                                            <p className="text-white">2500 University Dr NW</p>
                                        </div>
                                    </li>
                                    <li className="flex items-start justify-start">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill='#fff' viewBox="0 0 482.6 482.6" className="mt-1">
                                            <path d="M98.339 320.8c47.6 56.9 104.9 101.7 170.3 133.4 24.9 11.8 58.2 25.8 95.3 28.2 2.3.1 4.5.2 6.8.2 24.9 0 44.9-8.6 61.2-26.3.1-.1.3-.3.4-.5 5.8-7 12.4-13.3 19.3-20 4.7-4.5 9.5-9.2 14.1-14 21.3-22.2 21.3-50.4-.2-71.9l-60.1-60.1c-10.2-10.6-22.4-16.2-35.2-16.2-12.8 0-25.1 5.6-35.6 16.1l-35.8 35.8c-3.3-1.9-6.7-3.6-9.9-5.2-4-2-7.7-3.9-11-6-32.6-20.7-62.2-47.7-90.5-82.4-14.3-18.1-23.9-33.3-30.6-48.8 9.4-8.5 18.2-17.4 26.7-26.1 3-3.1 6.1-6.2 9.2-9.3 10.8-10.8 16.6-23.3 16.6-36s-5.7-25.2-16.6-36l-29.8-29.8c-3.5-3.5-6.8-6.9-10.2-10.4-6.6-6.8-13.5-13.8-20.3-20.1-10.3-10.1-22.4-15.4-35.2-15.4-12.7 0-24.9 5.3-35.6 15.5l-37.4 37.4c-13.6 13.6-21.3 30.1-22.9 49.2-1.9 23.9 2.5 49.3 13.9 80 17.5 47.5 43.9 91.6 83.1 138.7zm-72.6-216.6c1.2-13.3 6.3-24.4 15.9-34l37.2-37.2c5.8-5.6 12.2-8.5 18.4-8.5 6.1 0 12.3 2.9 18 8.7 6.7 6.2 13 12.7 19.8 19.6 3.4 3.5 6.9 7 10.4 10.6l29.8 29.8c6.2 6.2 9.4 12.5 9.4 18.7s-3.2 12.5-9.4 18.7c-3.1 3.1-6.2 6.3-9.3 9.4-9.3 9.4-18 18.3-27.6 26.8l-.5.5c-8.3 8.3-7 16.2-5 22.2.1.3.2.5.3.8 7.7 18.5 18.4 36.1 35.1 57.1 30 37 61.6 65.7 96.4 87.8 4.3 2.8 8.9 5 13.2 7.2 4 2 7.7 3.9 11 6 .4.2.7.4 1.1.6 3.3 1.7 6.5 2.5 9.7 2.5 8 0 13.2-5.1 14.9-6.8l37.4-37.4c5.8-5.8 12.1-8.9 18.3-8.9 7.6 0 13.8 4.7 17.7 8.9l60.3 60.2c12 12 11.9 25-.3 37.7-4.2 4.5-8.6 8.8-13.3 13.3-7 6.8-14.3 13.8-20.9 21.7-11.5 12.4-25.2 18.2-42.9 18.2-1.7 0-3.5-.1-5.2-.2-32.8-2.1-63.3-14.9-86.2-25.8-62.2-30.1-116.8-72.8-162.1-127-37.3-44.9-62.4-86.7-79-131.5-10.3-27.5-14.2-49.6-12.6-69.7z" data-original="#000000"></path>
                                        </svg>
                                        <div className="ml-4 text-sm text-gray-500">
                                            <p className="text-white">Phone</p>
                                            <p className="text-white hover:underline hover:cursor-pointer" onClick={() => window.location.href = 'tel:+14036152979'}>+1 123-456-7890</p>
                                        </div>
                                    </li>
                                    <li className="flex items-start justify-start">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill='#fff'
                                            viewBox="0 0 479.058 479.058">
                                            <path
                                                d="M434.146 59.882H44.912C20.146 59.882 0 80.028 0 104.794v269.47c0 24.766 20.146 44.912 44.912 44.912h389.234c24.766 0 44.912-20.146 44.912-44.912v-269.47c0-24.766-20.146-44.912-44.912-44.912zm0 29.941c2.034 0 3.969.422 5.738 1.159L239.529 264.631 39.173 90.982a14.902 14.902 0 0 1 5.738-1.159zm0 299.411H44.912c-8.26 0-14.971-6.71-14.971-14.971V122.615l199.778 173.141c2.822 2.441 6.316 3.655 9.81 3.655s6.988-1.213 9.81-3.655l199.778-173.141v251.649c-.001 8.26-6.711 14.97-14.971 14.97z"
                                                data-original="#000000" />
                                        </svg>
                                        <div className="ml-4 text-sm text-gray-500" >
                                            <p className="text-white">Email Address</p>
                                            <p className="text-white hover:underline hover:cursor-pointer" onClick={() => window.location.href = 'mailto:nsbeuofc@gmail.com'}>nsbeuofc@gmail.com</p>
                                        </div>
                                    </li>
                                </ul>
                                <div>
                                    <div className="flex items-center justify-center mt-8 text-white">
                                        FOLLOW US
                                    </div>
                                    <div className="flex items-center justify-center mt-4 follow-us-container flex-shrink gap-2">
                                        <a href="https://www.linkedin.com/company/nsbe-university-of-calgary/" target="_blank" rel="noopener noreferrer">
                                            <IoLogoLinkedin className="member-icon-button" />
                                        </a>
                                        <a href="https://discord.gg/7hJnzrur" target="_blank" rel="noopener noreferrer">
                                            <RiDiscordLine className="member-icon-button" />
                                        </a>
                                        <a href="https://www.instagram.com/nsbeucalgary?igsh=dTc5NG4wZDc1NmF4" target="_blank" rel="noopener noreferrer">
                                            <IoLogoInstagram className="member-icon-button" />
                                        </a>
                                        <a href="https://www.facebook.com/profile.php?id=61554684322155&mibextid=PtKPJ9" target="_blank" rel="noopener noreferrer">
                                            <SiFacebook className="member-icon-button" />
                                        </a>
                                        <a href="https://x.com/nsbeuofc" target="_blank" rel="noopener noreferrer">
                                            <FaXTwitter className="member-icon-button" />
                                        </a>
                                    </div>
                                </div>

                            </div>

                            <div className="p-4 lg:col-span-2">
                                <form ref={form} onSubmit={sendEmail}>
                                    <div className="grid sm:grid-cols-2 gap-8">
                                        <div className="relative flex flex-col sm:col-span-2">
                                            <label htmlFor="user_name" className="text-sm text-gray-600">Full Name</label>
                                            <input type="text" name="user_name" id="user_name"
                                                className="bg-transparent px-2 py-3 w-full text-sm text-gray-800 border-b border-black focus:border-blue-500 outline-none" required />
                                        </div>

                                        <div className="relative flex flex-col sm:col-span-2">
                                            <label htmlFor="user_email" className="text-sm text-gray-600">Email</label>
                                            <input type="email" name="user_email" id="user_email"
                                                className="bg-transparent px-2 py-3 text-black w-full text-sm text-gray-800 border-b border-black focus:border-blue-500 outline-none" required />
                                        </div>

                                        <div className="relative flex flex-col sm:col-span-2">
                                            <label htmlFor="message" className="text-sm text-gray-600">Message</label>
                                            <textarea id="message" name="message"
                                                className="bg-transparent px-2 pt-3 text-black w-full text-sm text-gray-800 border-b border-black focus:border-blue-500 outline-none" required></textarea>
                                        </div>
                                    </div>

                                    <div className="flex justify-center mt-12">
                                        <button type="submit" value="Send" id="contact-button"
                                            className="text-sm rounded-lg px-8 py-3 tracking-wide text-white bg-theme-color">
                                            Send Message
                                        </button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                    <div className="map-container max-w-3xl max-lg:max-w-3xl item-center justify-center mx-auto p-4">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2506.7835558466495!2d-114.13232932310294!3d51.07554547171831!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53716f08488ad5a7%3A0x98ebb5d9af098f8d!2sArt%20Building%2C%202500%20University%20Dr%20NW%2C%20Calgary%2C%20AB%20T2N%201N4!5e0!3m2!1sen!2sca!4v1724579634174!5m2!1sen!2sca" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" className='w-full h-full'></iframe>
                    </div>
                </div>
            </section>


            <footer className="bg-theme-color">
                <div className="mx-auto w-3/5 max-w-screen-xl p-4 py-6 lg:py-8">
                    <div className="text-white text-center font-semibold text-md md:text-3xl">
                        Make a difference with us. Volunteer, Support, Advocate. Get Involved Today!
                    </div>
                    <div className="flex flex-col space-y-4 items-center mt-4 sm:flex-row sm:items-center sm:justify-evenly sm:mt-12 sm:space-y-0">
                        <button className="contact-button w-3/4 sm:w-1/4" onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSfplx30HQHxu1OfNIjlSgTWFdMEh1KOWg6SuE553nPf1osPXA/viewform', '_blank')}
                        >Become a member</button>
                        <button className="contact-button w-3/4 sm:w-1/4" onClick={() => window.location.href = '/GetInvolved'}>Get Involved</button>
                    </div>
                    <div className="mt-6 flex justify-center">
                        <span className="text-sm text-black font-semibold md:text-xl whitespace-nowrap">
                            © 2024 <a href="https://nsbeucalgary.ca/" className="hover:underline">NSBEUCALGARY</a>. All Rights Reserved.
                        </span>
                    </div>
                </div>
            </footer>

        </>
    );
}

export default Contact;