import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { format } from 'date-fns';
import '../../styles/Event.css';

const events = [
    {
        id: 1,
        title: 'Meet and Greet',
        date: new Date(2024, 9, 11, 16), // October 11th, 4 PM
    },
    {
        id: 2,
        title: 'Freshman 101',
        date: new Date(2024, 9, 17, 17), // October 17th, 5 PM
    },
    {
        id: 3,
        title: 'Study Night #1',
        date: new Date(2024, 9, 21, 15), // October 21st, 3 PM
    },
    {
        id: 4,
        title: 'Prof Dev Workshop #1 (Resume + Cover Letter)',
        date: new Date(2024, 9, 28, 17), // October 28th, 5 PM
    },
    {
        id: 5,
        title: 'Career Fair',
        date: new Date(2024, 10, 5), // November 5th
    },
    {
        id: 6,
        title: 'Study Night #2',
        date: new Date(2024, 10, 11), // November 11th
    },
    {
        id: 7,
        title: 'Bake Sale Fundraiser',
        date: new Date(2024, 10, 20), // November 20th
    },
    {
        id: 8,
        title: 'Portfolio Design Workshop',
        date: new Date(2024, 10, 26), // November 26th
    },
    {
        id: 9,
        title: 'Research Night',
        date: new Date(2024, 11, 5), // December 5th
    },
    {
        id: 10,
        title: 'Prof Dev Workshop #2',
        date: new Date(2024, 11, 11), // December 11th
    },
    {
        id: 11,
        title: 'Study Night #3',
        date: new Date(2024, 11, 19), // December 19th
    }
];

const EventCalendar = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());

    return (
        <div className="bg-neon-color p-4">
            <Calendar
                // className="w-full"
                onChange={setSelectedDate}
                value={selectedDate}
                tileContent={({ date, view }) => {
                    const dailyEvents = events.filter(
                        (event) =>
                            format(event.date, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd')
                    );

                    return dailyEvents.length > 0 ? (
                        <div className="event-details text-xs text-blue-900 mt-1">
                            {dailyEvents.map((event) => (
                                <div key={event.id}>{event.title}</div>
                            ))}
                        </div>
                    ) : null;
                }}
                tileClassName={({ date, view }) => {
                    const isSelected =
                        format(date, 'yyyy-MM-dd') === format(selectedDate, 'yyyy-MM-dd');
                    return isSelected ? 'bg-blue-100' : '';
                }}
            />
        </div>
    );
};

export default EventCalendar;