import React from 'react';
import '../styles/Event.css';
import event1 from '../assets/events/Study.png';
import event2 from '../assets/events/blm.png';
import event3 from '../assets/events/bake_sale.png';
import event4 from '../assets/events/Blue_Modern_Career_Fair_Instagram_Post.png'
import event5 from '../assets/events/Light_Pink_Blue_Minimalist_Illustration_National_Science_Day_Instagram_Feed.png'

import EventCalendar from '../components/Event/EventCalendar';

function Event() {
    return (
        <>
            <section className="section2" id="event">
                <h2 className="page-header">EVENTS</h2>
                <div className="container mx-auto px-4 mt-8">
                    <EventCalendar />
                </div>
            </section>

            <div className='bg-theme-color text-center'>
                <h2 className="text-white text-md font-semibold pt-8 md:text-xl">COMING SOON...</h2>
                <h3 className='text-white text-sm font-semibold md:text-base'>Stay tuned and watch out for these upcoming events in the fall semester</h3>
                <div className='flex flex-col items-center space-y-8 mt-8 md:flex-row md:items-center md:justify-evenly md:space-y-0'>
                    <img src={event4} alt="Event 4" className="event-image" />
                    <img src={event5} alt="Event 5" className="event-image" />
                </div>

            </div>
            <footer className="bg-theme-color">
                <div className="mx-auto w-3/5 max-w-screen-xl p-4 py-6 lg:py-8">
                    <div className="text-white text-center font-semibold text-md md:text-3xl">
                        Make a difference with us. Volunteer, Support, Advocate. Get Involved Today!
                    </div>
                    <div className="flex flex-col space-y-4 items-center mt-4 sm:flex-row sm:items-center sm:justify-evenly sm:mt-12 sm:space-y-0">
                        <button className="contact-button w-3/4 sm:w-1/4" onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSfplx30HQHxu1OfNIjlSgTWFdMEh1KOWg6SuE553nPf1osPXA/viewform', '_blank')}
                            >Become a member</button>
                        <button className="contact-button w-3/4 sm:w-1/4" onClick={() => window.location.href = '/GetInvolved'}>Get Involved</button>
                    </div>
                    <div className="mt-6 flex justify-center">
                        <span className="text-sm text-black font-semibold md:text-xl whitespace-nowrap">
                            © 2024 <a href="https://nsbeucalgary.ca/" className="hover:underline">NSBEUCALGARY</a>. All Rights Reserved.
                        </span>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Event;

